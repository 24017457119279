<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-white" v-if="$store.getters['auth/check']">
      <div class="container-fluid">
        <div class="row w-100">
          <div class="col-9 col-sm-10 col-md-9 col-lg-10">
            <div class="row" id="navbar-pill-row">
              <div class="col-2">
                <Slide width="270" :closeOnNavigation="true">
                  <router-link :to="{ name: 'categories.list' }" class="dropdown-item pl-3 d-flex align-items-center">
                    <fa icon="sitemap" style="color: #263586" class="mr-1" fixed-width/>
                    {{ $tc('general.common.category', 2) }}
                  </router-link>
                  <router-link :to="{ name: 'evidences.required' }" class="dropdown-item pl-3 d-flex align-items-center" v-if="user.role_has_levels || user.role === 'Manager'">
                    <fa icon="scroll" style="color: #B15E1C" class="mr-1" fixed-width/>
                    {{ $t('general.common.evidence') }}
                  </router-link>
                  <router-link :to="{ name: 'training.list' }" class="dropdown-item pl-3 d-flex align-items-center">
                    <fa icon="graduation-cap" style="color: #467D3F" class="mr-1" fixed-width/>
                    {{ $tc('general.common.booked_training') }}
                  </router-link>
                  <router-link :to="{ name: 'training-requests.list' }" class="dropdown-item pl-3 d-flex align-items-center">
                    <fa icon="ticket-alt" style="color: #7d1f1a" class="mr-1" fixed-width/>
                    {{ $tc('general.common.training_request', 2) }}
                  </router-link>
                  <router-link :to="{ name: 'meetings.list' }" class="dropdown-item pl-3 d-flex align-items-center">
                    <fa icon="calendar-alt" style="color: #732049" class="mr-1" fixed-width/>
                    {{ $tc('general.common.meeting', 2) }}
                  </router-link>

                  <hr v-if="$store.getters['auth/hasPermission']('view admin')">

                  <router-link v-if="$store.getters['auth/hasPermission']('view admin')" :to="{ name: 'admin.stats' }" class="dropdown-item pl-3 d-flex align-items-center">
                    <fa icon="cog" style="color: #000000" class="mr-1" fixed-width/>
                    {{ $t('section.nav.settings') }}
                  </router-link>
                </Slide>
              </div>
              <div class="col-10 angled-box-right d-flex align-items-center">
                <router-link :to="{ name: user ? 'dashboard' : 'login' }" class="navbar-brand">
                  <img src="/images/logo_white_without_text.png" alt="SMT Logo" width="100px">
                </router-link>
                <h1 id="page-title">
                  <template v-if="$route.meta.title && !$route.meta.dynamicTitle">
                    {{ ($te('section.nav.'+$route.meta.title) ? $t('section.nav.'+$route.meta.title) : $tc('general.common.'+$route.meta.title, 2)) }}
                  </template>

                  <template v-else>
                    {{ $store.getters['page-info/pageTitle'] }}
                  </template>
                </h1>
              </div>
            </div>
          </div>
          <div class="col-3 col-sm-2 col-md-3 col-lg-2">
            <ul v-if="user" class="navbar-nav" id="navbar-user-menu">
              <!-- Authenticated -->
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle text-dark"
                   href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="text-center">
                    <fa icon="user-circle" id="default-avatar" fixed-width/>
                    <!--<img :src="user.photo_url" class="rounded-circle profile-photo">-->
                  </div>

                  <span id="name-text">{{ user.first_name | capitalize }} {{ user.last_name | capitalize }}</span>
                </a>
                <div class="dropdown-menu">
                  <router-link :to="{ name: 'settings.profile' }" class="dropdown-item pl-3">
                    <fa icon="cog" fixed-width/>
                    {{ $t('section.nav.user_settings') }}
                  </router-link>

                  <router-link :to="{ name: 'users.feedback' }" class="dropdown-item pl-3">
                    <fa icon="comment" fixed-width/>
                    {{ $t('section.nav.user_feedback') }}
                  </router-link>

                  <!--<div class="dropdown-divider"></div>-->
                  <a @click.prevent="logout" class="dropdown-item pl-3"  href="#">
                    <fa icon="sign-out-alt" fixed-width/>
                    {{ $t('general.operation.logout') }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleDropdown from './LocaleDropdown'
import { Slide } from 'vue-burger-menu'

export default {
  data: () => ({
    appName: window.config.appName
  }),

  computed: mapGetters({
    user: 'auth/user'
  }),

  components: {
    LocaleDropdown,
    Slide
  },

  methods: {
    async logout () {
      // Log out the user.
      await this.$store.dispatch('auth/logout')

      // Redirect to login.
      await this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../sass/variables";
  @import '~bootstrap/scss/bootstrap';

  .profile-photo {
    width: 40px;
    height: 40px;
  }

  .dropdown-toggle::after {
    display: none;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .navbar {
    box-shadow: none;
  }

  #default-avatar {
    font-size: 40px;
    color: $primary-blue;
  }

  .navbar-brand {
    font-size: 40px;
    text-decoration: underline;
    color: #FFFFFF;
  }

  #navbar-pill-row {
    ul {
      height: 100%;
      align-items: center;
      justify-content: center;

      .nav-item .dropdown-menu {
        margin-top: 1rem;
        background-color: $light-grey;
      }

      .nav-item .dropdown-menu:after {
        position: absolute;
        top: -13px;
        left: -2px;
        width: 0;
        height: 0;
        content: '';
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $light-grey;
      }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
      }
    }
    .col-2 {
      background-color: $primary-blue;
      flex: 0 0 5%;
    }
    .col-10 {
      background-color: $primary-blue;
      flex: 1 0 83%;
      max-width: none;
    }
  }

  #navbar-user-menu {
    align-content: flex-end;

    height: 100%;
    align-items: center;
    justify-content: center;

    .nav-item .dropdown-menu {
      margin-top: 1rem;
      background-color: $light-grey;
      top: 90%;
    }

    .nav-item .dropdown-menu:after {
      position: absolute;
      top: -13px;
      left: 53px;
      width: 0;
      height: 0;
      content: '';
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid $light-grey;
    }
  }

  #page-title {
    color: #FFFFFF;
    font-weight: 500;
    margin: 0 0 0 20px;
  }

  @include media-breakpoint-down(md) {
    .navbar-nav {
      .dropdown-menu {
        position: absolute;
        left: -6px;
      }
    }

    #navbar-user-menu {
      .dropdown-menu {
        left: -15px;
        top: 84%;
      }

      .nav-item .dropdown-menu:after {
        left: 60px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    #name-text {
      display: none;
    }

    #page-title {
      display: none;
    }

    #default-avatar {
      font-size: 50px;
    }

    #navbar-pill-row {
      ul {
        .nav-item .dropdown-menu:after {
          left: -2px;
        }
      }
    }

    #navbar-user-menu {
      .dropdown-menu {
        left: -81px;
        top: 84%;
      }

      .nav-item .dropdown-menu:after {
        left: 89px;
      }
    }
  }


</style>
