import Vue from 'vue'
import Card from './Card.vue'
import Child from './Child.vue'
import Button from './Button.vue'
import { HasError, AlertError, AlertSuccess } from 'vform'
import HighchartsVue from 'highcharts-vue'

// Components that are registered globally.
[
  Card,
  Child,
  Button,
  HasError,
  AlertError,
  AlertSuccess
].forEach(Component => {
  Vue.component(Component.name, Component)
})

Vue.use(HighchartsVue)
