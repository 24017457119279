import axios from 'axios'
import store from '~/store'
import errorHandler from '~/plugins/error-handler'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => {
  const { authorization } = response.headers

  if (authorization) {
    store.dispatch('auth/saveToken', {
      token: authorization.split(' ').pop(),
      remember: false
    }).catch()
  }

  response.data.headers = response.headers

  return response.data
}, error => {
  if (error.response) {
    const { authorization } = error.response.headers

    if (authorization) {
      store.dispatch('auth/saveToken', {
        token: authorization.split(' ').pop(),
        remember: false
      }).catch()
    }
  }

  if (!axios.isCancel(error)) {
    errorHandler.handleAPI(error)
  }

  return Promise.reject(error)
})
