var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-layout" },
    [
      _c("navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "container-fluid" }, [_c("child")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }