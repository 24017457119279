<template>
  <div class="card" :class="card_classes">
    <div v-if="title" class="card-header" :class="{'card-header-bold': make_title_bold}">
      {{ title }}
    </div>

    <div class="card-body" :class="cardBodyClasses">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    'title': String,
    'card_classes': String,
    'cardBodyClasses': String,
    'make_title_bold': {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .card-header-bold {
    font-weight: bold;
  }
</style>
