var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "badger-accordion-item" }, [
    _c("dt", { staticClass: "badger-accordion__header" }, [
      _c(
        "div",
        { staticClass: "js-badger-accordion-header card-header" },
        [
          _c(_vm.iconLoaded, {
            tag: "span",
            staticClass: "badger-toggle-indicator"
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "badger-accordion-toggle" },
            [_vm._t("header", [_vm._v("Collapse-Title")])],
            2
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "dd",
      { staticClass: "badger-accordion__panel js-badger-accordion-panel" },
      [
        _c(
          "div",
          { staticClass: "js-badger-accordion-panel-inner card-body" },
          [_vm._t("content")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }