var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn",
      class:
        ((_obj = {}),
        (_obj["btn-" + _vm.type] = true),
        (_obj["btn-block"] = _vm.block),
        (_obj["btn-lg"] = _vm.large),
        (_obj["btn-loading"] = _vm.loading),
        _obj),
      attrs: { type: _vm.nativeType, disabled: _vm.loading }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }