const SplashScreen = () => import('~/pages/splash-screen')
const Login = () => import('~/pages/login/index')
const LoginForm = () => import('~/pages/login/login')
// const PasswordEmail = () => import('~/pages/login/email')
// const PasswordReset = () => import('~/pages/login/reset')

const Dashboard = () => import('~/pages/dashboard')
const Settings = () => import('~/pages/settings/index')
const SettingsProfile = () => import('~/pages/settings/profile')
// const SettingsPassword = () => import('~/pages/settings/password')

const Admin = () => import('~/pages/admin/index')

const AdminStats = () => import('~/pages/admin/stats')

const AdminUsers = () => import('~/pages/admin/users/list')
const AdminUsersDetail = () => import('~/pages/admin/users/detail')
const AdminUsersEdit = () => import('~/pages/admin/users/edit')

const AdminTeams = () => import('~/pages/admin/teams/list')
const AdminTeamsDetail = () => import('~/pages/admin/teams/detail')
const AdminTeamsEdit = () => import('~/pages/admin/teams/edit')

const AdminLists = () => import('~/pages/admin/lists/list')
const AdminPermissions = () => import('~/pages/admin/permissions/list')
const AdminRolesList = () => import('~/pages/admin/roles/list')
const AdminRolesDetail = () => import('~/pages/admin/roles/detail')
const AdminRolesEdit = () => import('~/pages/admin/roles/edit')
const AdminAudits = () => import('~/pages/admin/audits/list')
const AdminTrash = () => import('~/pages/admin/trash/list')
const AdminMeetingsList = () => import('~/pages/admin/meeting-types/list')
const AdminMeetingsEdit = () => import('~/pages/admin/meeting-types/edit')

const AdminCategories = () => import('~/pages/admin/categories/list')
const AdminCategoriesDetail = () => import('~/pages/admin/categories/detail')
const AdminCategoriesEdit = () => import('~/pages/admin/categories/edit')
const AdminSubjectsDetail = () => import('~/pages/admin/subjects/detail')
const AdminMeasuresDetail = () => import('~/pages/admin/measures/detail')

const AdminCourses = () => import('~/pages/admin/courses/list')
const AdminCoursesEdit = () => import('~/pages/admin/courses/edit')

const AdminTrainingSessions = () => import('~/pages/admin/training-sessions/list')
const AdminTrainingRequests = () => import('~/pages/admin/training-requests/list')
const AdminTrainingSessionEdit = () => import('~/pages/admin/training-sessions/edit')

const CategoriesLists = () => import('~/pages/categories/list')
const CategoriesLevelLists = () => import('~/pages/categories/level-list')
const CategoriesUserLevelLists = () => import('~/pages/categories/user-level-list')
const CategoriesDetail = () => import('~/pages/categories/detail')
const CategoriesLevel = () => import('~/pages/categories/level')
const CategoriesMeasure = () => import('~/pages/categories/measure')

const LevelCategories = () => import('~/pages/levels/categories')

const EvidencesRequired = () => import('~/pages/evidences/required')
const EvidencesAwaitingApproval = () => import('~/pages/evidences/awaiting-approval')

const TrainingLists = () => import('~/pages/training/list')
const TrainingRequestsLists = () => import('~/pages/training-requests/list')

const MeetingsLists = () => import('~/pages/meetings/list')
const MeetingForm = () => import('~/pages/meetings/meeting-form')
const MeetingDetail = () => import('~/pages/meetings/detail')

const TeamsList = () => import('~/pages/teams/list')

const UsersList = () => import('~/pages/users/list')
const UsersFeedback = () => import('~/pages/users/feedback')
const UsersTeamList = () => import('~/pages/users/team-list')

const AuditList = () => import('~/pages/audits/list')

const ReportsDetail = () => import('~/pages/reports/detail')

export default [
  { path: '/', name: 'splash-screen', component: SplashScreen },
  { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { title: 'dashboard' }},

  { path: '/login', component: Login, children:
    [
      { path: '', name: 'login', component: LoginForm }
      // { path: 'password/reset', name: 'password.request', component: PasswordEmail },
      // { path: 'password/reset/:token', name: 'password.reset', component: PasswordReset }
    ]
  },

  { path: '/settings', component: Settings, children:
    [
      { path: '', redirect: { name: 'settings.profile' }},
      { path: 'profile', name: 'settings.profile', component: SettingsProfile, meta: { title: 'settings' }}
      // { path: 'password', name: 'settings.password', component: SettingsPassword, meta: { title: 'settings' }}
    ]
  },

  { path: '/admin', component: Admin, children:
      [
        { path: '', redirect: { name: 'admin.stats' }},

        { path: 'stats', name: 'admin.stats', component: AdminStats, meta: { title: 'stats', module: 'admin', permission: 'view', adminOnly: true }},

        { path: 'users', name: 'admin.users', component: AdminUsers, meta: { title: 'user', module: 'users', permission: 'view', adminOnly: true }},
        { path: 'users/:id', name: 'admin.users.detail', component: AdminUsersDetail, meta: { title: 'user_detail', module: 'users', permission: 'view', adminOnly: true }, props: true },
        { path: 'users/:id/edit', name: 'admin.users.edit', component: AdminUsersEdit, meta: { title: 'user_edit', module: 'users', permission: 'edit', adminOnly: true }},

        { path: 'teams', name: 'admin.teams', component: AdminTeams, meta: { title: 'team', module: 'teams', permission: 'view', adminOnly: true }},
        { path: 'teams/:id', name: 'admin.teams.detail', component: AdminTeamsDetail, meta: { title: 'team_detail', module: 'teams', permission: 'view', adminOnly: true }},
        { path: 'teams/:id/edit', name: 'admin.teams.edit', component: AdminTeamsEdit, meta: { title: 'team_edit', module: 'teams', permission: 'edit', adminOnly: true }},

        { path: 'lists', name: 'admin.lists', component: AdminLists, meta: { title: 'list', module: 'lists', permission: 'view', adminOnly: true }},
        { path: 'permissions', name: 'admin.permissions', component: AdminPermissions, meta: { title: 'permission', module: 'permissions', permission: 'view', adminOnly: true }},
        { path: 'roles', name: 'admin.roles', component: AdminRolesList, meta: { title: 'role', module: 'roles', permission: 'view', adminOnly: true }},
        { path: 'roles/create', name: 'admin.roles.create', component: AdminRolesEdit, props: true, meta: { title: 'role_create', module: 'roles', permission: 'edit', adminOnly: true, dynamicTitle: true }},
        { path: 'roles/:id', name: 'admin.roles.detail', component: AdminRolesDetail, props: true, meta: { title: 'role_detail', module: 'roles', permission: 'view', adminOnly: true, dynamicTitle: true }},
        { path: 'roles/:id/edit', name: 'admin.roles.edit', component: AdminRolesEdit, props: true, meta: { title: 'role_edit', module: 'roles', permission: 'edit', adminOnly: true, dynamicTitle: true }},

        { path: 'meeting-types', name: 'admin.meeting-types', component: AdminMeetingsList, meta: { title: 'meeting_type', module: 'meeting-types', permission: 'view', adminOnly: true }},
        { path: 'meeting-types/create', name: 'admin.meeting-types.create', component: AdminMeetingsEdit, meta: { title: 'meeting_type_create', module: 'meeting-types', permission: 'edit', adminOnly: true }},
        { path: 'meeting-types/:id', name: 'admin.meeting-types.edit', component: AdminMeetingsEdit, props: true, meta: { title: 'meeting_type_edit', module: 'meeting-types', permission: 'edit', adminOnly: true }},
        { path: 'audits', name: 'admin.audits', component: AdminAudits, meta: { title: 'audit', module: 'audits', permission: 'view', adminOnly: true }},
        { path: 'trash', name: 'admin.trash', component: AdminTrash, meta: { title: 'trash', module: 'trash', permission: 'view', adminOnly: true }},

        { path: 'categories', name: 'admin.categories', component: AdminCategories, props: true, meta: { title: 'category', module: 'categories', permission: 'view', adminOnly: true }},
        { path: 'categories/:cat_id', name: 'admin.category.detail', component: AdminCategoriesDetail, props: true, meta: { title: 'category_detail', module: 'categories', permission: 'view', adminOnly: true }},
        { path: 'subjects/:id/:category_id?/:level_id?', name: 'admin.subjects.detail', component: AdminSubjectsDetail, meta: { title: 'subject_detail', module: 'subjects', permission: 'view', adminOnly: true }, props: true },
        { path: 'categories/:cat_id/level/:level_id/subject/:subject_id/measure/:measure_id', name: 'admin.category.edit', component: AdminCategoriesEdit, props: true, meta: { title: 'category_edit', module: 'categories', permission: 'edit', adminOnly: true, dynamicTitle: true }},
        { path: 'categories/:cat_id/level/:level_id', name: 'admin.category.subject.add', component: AdminCategoriesEdit, props: true, meta: { title: 'category_edit', module: 'categories', permission: 'edit', adminOnly: true, dynamicTitle: true }},
        { path: 'measures/:id', name: 'admin.measures.detail', component: AdminMeasuresDetail, meta: { title: 'measure_detail', module: 'measures', permission: 'view', adminOnly: true }},

        { path: 'courses', name: 'admin.courses', component: AdminCourses, meta: { title: 'course', module: 'courses', permission: 'view', adminOnly: true }},
        { path: 'courses/create', name: 'admin.courses.create', component: AdminCoursesEdit, meta: { title: 'course_add', module: 'courses', permission: 'create', adminOnly: true }},
        { path: 'courses/:id/edit', name: 'admin.courses.edit', component: AdminCoursesEdit, props: true, meta: { title: 'course_edit', module: 'courses', permission: 'edit', adminOnly: true }},

        { path: 'training-sessions', name: 'admin.training-sessions', component: AdminTrainingSessions, meta: { title: 'training_session', module: 'training-sessions', permission: 'view', adminOnly: true }},
        { path: 'training-requests', name: 'admin.training-requests', component: AdminTrainingRequests, meta: { title: 'training_request', module: 'training-requests', permission: 'view', adminOnly: true }},
        { path: 'training-sessions/create', name: 'admin.training-sessions.create', component: AdminTrainingSessionEdit, meta: { title: 'add_training_session', module: 'training-sessions', permission: 'create', adminOnly: true }},
        { path: 'training-sessions/:id/edit', name: 'admin.training-sessions.edit', component: AdminTrainingSessionEdit, props: true, meta: { title: 'training_session', module: 'training-sessions', permission: 'edit', adminOnly: true }}
      ]
  },

  { path: '/categories', name: 'categories.list', component: CategoriesLists, props: true, meta: { title: 'category', module: 'categories', permission: 'view' }},
  { path: '/categories/levels', name: 'categories.level-list', component: CategoriesLevelLists, props: true, meta: { title: 'cats_and_levels', module: 'categories', permission: 'view' }},
  { path: '/categories/:cat_id', name: 'categories.detail', component: CategoriesDetail, props: true, meta: { title: 'category_detail', module: 'categories', permission: 'view', dynamicTitle: true }},
  { path: '/categories/:cat_id/level/:level_id', name: 'categories.level', component: CategoriesLevel, props: true, meta: { title: 'category_level', module: 'subjects', permission: 'view', dynamicTitle: true }},
  { path: '/categories/:cat_id/level/:level_id/subject/:subject_id/measure/:measure_id', name: 'categories.level.measure', component: CategoriesMeasure, props: true, meta: { title: 'category_measure', module: 'measures', permission: 'view', dynamicTitle: true }},

  { path: '/level/:id/categories', name: 'level.categories', component: LevelCategories, props: true, meta: { title: 'level_categories', module: 'levels', permission: 'view', dynamicTitle: true }},

  { path: '/evidences/required', name: 'evidences.required', component: EvidencesRequired, meta: { title: 'evidence', module: 'evidences', permission: 'view' }},
  { path: '/evidences/awaiting-approval', name: 'evidences.awaiting-approval', component: EvidencesAwaitingApproval, meta: { title: 'awaiting_approval', module: 'evidences', permission: 'view' }},

  { path: '/booked-training', name: 'training.list', component: TrainingLists, meta: { title: 'booked_training', module: 'training-sessions', permission: 'view' }},
  { path: '/training-requests', name: 'training-requests.list', component: TrainingRequestsLists, meta: { title: 'training_request', module: 'training-requests', permission: 'view' }},

  { path: '/meetings', name: 'meetings.list', component: MeetingsLists, meta: { title: 'meeting', module: 'meetings', permission: 'view' }},
  { path: '/meetings/:id', name: 'meetings.form', component: MeetingForm, props: true, meta: { title: 'meeting_form', module: 'meetings', permission: 'view' }},
  { path: '/meetings/:id/detail', name: 'meetings.detail', component: MeetingDetail, props: true, meta: { title: 'meeting_detail', module: 'meetings', permission: 'view' }},

  { path: '/teams', name: 'teams.list', component: TeamsList, meta: { title: 'team', module: 'teams', permission: 'view' }},

  { path: '/users', name: 'users.list', component: UsersList, meta: { title: 'user', module: 'users', permission: 'view' }},
  { path: '/users/feedback', name: 'users.feedback', component: UsersFeedback, meta: { title: 'user_feedback' }},
  { path: '/users/team/:id', name: 'users.team-list', component: UsersTeamList, props: true, meta: { title: 'team_overview', module: 'users', permission: 'view' }},
  { path: '/users/:user_id/level/:id/categories', name: 'users.level.categories', component: LevelCategories, props: true, meta: { title: 'level_categories', module: 'levels', permission: 'view', dynamicTitle: true }},
  { path: '/users/:user_id/categories/levels', name: 'users.categories.user-level-list', component: CategoriesUserLevelLists, props: true, meta: { title: 'cats_and_levels', module: 'categories', permission: 'view', dynamicTitle: true }},
  { path: '/users/:user_id/categories/:cat_id', name: 'users.categories.detail', component: CategoriesDetail, props: true, meta: { title: 'category_detail', module: 'categories', permission: 'view', dynamicTitle: true }},
  { path: '/users/:user_id/categories/:cat_id/level/:level_id', name: 'users.categories.level', component: CategoriesLevel, props: true, meta: { title: 'category_level', module: 'categories', permission: 'view', dynamicTitle: true }},
  { path: '/users/:user_id/categories/:cat_id/level/:level_id/subject/:subject_id/measure/:measure_id', name: 'users.categories.level.measure', component: CategoriesMeasure, props: true, meta: { title: 'category_measure', module: 'measures', permission: 'view', dynamicTitle: true }},

  { path: '/audits', name: 'audits.list', component: AuditList, meta: { title: 'audit', module: 'audits', permission: 'view' }},

  { path: '/reports/:report_id', name: 'reports.detail', component: ReportsDetail, props: true, meta: { title: 'report', module: 'reports', permission: 'view', dynamicTitle: true }},

  { path: '*', component: require('~/pages/errors/404') }
]
