var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card", class: _vm.card_classes }, [
    _vm.title
      ? _c(
          "div",
          {
            staticClass: "card-header",
            class: { "card-header-bold": _vm.make_title_bold }
          },
          [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body", class: _vm.cardBodyClasses },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }