import swal from 'sweetalert2'
import i18n from '~/plugins/i18n'
import router from '~/router'
import store from '~/store'
import axios from 'axios'

export default class ErrorHandler {
  static showError (
    errorTitle = i18n.t('general.error.error_alert_title'),
    errorText = i18n.t('general.error.error_alert_text'),
    type = 'error',
    toast = false,
  ) {
    if (!['warning', 'error', 'success', 'info'].includes(type)) {
      throw new Error('Error type must be warning, error, success or info')
    }

    if (toast) {
      swal.fire({
        type: type,
        title: errorTitle,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000
      })
    } else {
      swal.fire({
        type: type,
        title: errorTitle,
        text: errorText,
        reverseButtons: true,
        confirmButtonText: i18n.t('general.operation.ok'),
        cancelButtonText: i18n.t('general.operation.cancel')
      })
    }
  }

  static showErrorInToast (errorTitle = i18n.t('general.error.error_alert_title'), type = 'error') {
    this.showError(errorTitle, i18n.t('general.error.error_alert_text'), type, true)
  }

  static handle (
    error,
    errorTitle = i18n.t('general.error.error_alert_title'),
    errorText = i18n.t('general.error.error_alert_text'),
    type = 'error',
    toast = false
  ) {
    if (axios.isCancel(error)) {
      return
    }

    if (!error.response) {
      return
    }

    const errorType = error.response.data.type

    if (['VALIDATION_EXCEPTION', 'AUTHORIZATION_EXCEPTION'].includes(errorType)) {
      return
    }

    this.showError(errorTitle, errorText, type, toast)
  }

  static handleAPI (
    error,
    errorTitle = i18n.t('general.error.error_alert_title'),
    errorText = i18n.t('general.error.error_alert_text')
  ) {
    if (!error.response) {
      this.showError(errorTitle, errorText)
      return
    }

    const { status } = error.response

    const data = error.response.data

    switch (status) {
      case 500:
        this.handle500(data)
        break
      case 401:
        this.handle401(data)
        break
      case 403:
        this.handle403(data)
        break
      case 429:
        this.handle429(data)
        break
    }
  };

  static handle500 (data) {
    switch (data.type) {
      default:
        this.showError()
        break
    }
  }

  static handle401 (data) {
    if (store.getters['auth/check']) {
      store.dispatch('auth/logout')
    } else if (!store.getters['auth/check']) {
      this.goToLogin()
    }

    switch (data.type) {
      case 'TOKEN_BLACKLISTED_EXCEPTION':
        router.push({ name: 'login' })

        this.showError(i18n.t('auth.error.token_expired_alert_title'), i18n.t('auth.error.token_expired_alert_text'))
        break
      case 'UNAUTHORIZED_HTTP_EXCEPTION':
        this.goToLogin()

        this.showError(i18n.t('auth.error.token_expired_alert_title'), i18n.t('auth.error.token_expired_alert_text'))
        break
      case 'AUTHENTICATION_EXCEPTION':
        this.showError(i18n.t('auth.error.login_error_alert_title'), i18n.t('auth.error.login_error_alert_text'))
        break
      default:
        this.goToLogin()

        this.showError(i18n.t('auth.error.login_error_alert_title'), i18n.t('auth.error.login_error_alert_text'))
        break
    }
  }

  static handle403 (data) {
    switch (data.type) {
      case 'AUTHORIZATION_EXCEPTION':
        console.log(router.currentRoute.path)
        if (router.currentRoute.path !== '/login/' && router.currentRoute.path !== '/login') {
          router.back()

          this.showError(i18n.t('general.error.permission_denied'), '', 'error', true)
        } else {
          this.showError(i18n.t('auth.error.permission_denied_title'), i18n.t('auth.error.permission_denied_text'))
        }
        break
      default:
        this.showError()
        break
    }
  }

  static handle429 (data) {
    switch (data.type) {
      case 'THROTTLE_REQUESTS_EXCEPTION':
        this.showError(i18n.t('auth.error.rate_limit_hit_title'), i18n.t('auth.error.rate_limit_hit_text'))
        break
      default:
        this.showError()
        break
    }
  }

  static goToLogin () {
    if (router.currentRoute.path !== '/login/' && router.currentRoute.path !== '/login') {
      router.push({ name: 'login' })
    }
  }
}
