var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "text-center" }, [
    _c("h3", { staticClass: "mb-4" }, [
      _vm._v(_vm._s(_vm.$t("general.error.page_not_found")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "links" },
      [
        _c("router-link", { attrs: { to: { name: "login" } } }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("general.operation.go_home")) + "\n    "
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }