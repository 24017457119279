import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import App from '~/components/App.vue'
import i18n from '~/plugins/i18n'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import '~/plugins'
import '~/components'

window.moment = require('moment')

Vue.config.productionTip = false

Vue.prototype._ = _

new Vue({
  i18n,
  store,
  router,
  ...App
}).$mount('#app')

Sentry.init({
  dsn: window.config.sentryDsn,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  release: 'smt-edge@1.10.6',
  environment: window.config.sentryEnvironment,
  beforeSend: (event, hint) => {
    const capturedObj = hint.originalException

    if (typeof capturedObj === 'undefined' || !capturedObj) {
      return null
    }

    if (
      [
        'Request failed with status code 422'
      ].includes(capturedObj.message)
    ) {
      return null
    }

    return event
  }
})
