import Vue from 'vue'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faUser, faLock, faSignOutAlt, faCog, faCircle, faUserCircle, faBuilding,
  faListUl, faUsersCog, faHistory, faTrash, faSort, faSortDown, faSortUp,
  faInfoCircle, faEdit, faInfo, faSpinner, faSitemap, faScroll, faGraduationCap,
  faStarHalfAlt, faPercentage, faUndo, faFile, faTimesCircle, faLayerGroup,
  faFileAlt, faComment, faLink, faUnlink, faCalendarAlt, faCheckCircle, faClock,
  faBan, faUsers, faCheck, faTimes, faAngleDown, faAngleRight, faBold, faItalic,
  faStrikethrough, faUnderline, faCode, faParagraph, faListOl, faQuoteRight, faFileCsv,
  faCalendar, faChevronRight, faChevronDown, faChalkboardTeacher, faQuestionCircle,
  faUserSecret, faTicketAlt
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faUser, faLock, faSignOutAlt, faCog, faGithub, faCircle, faUserCircle, faCog,
  faBuilding, faListUl, faUsersCog, faHistory, faTrash, faSort, faSortDown,
  faSortUp, faInfoCircle, faEdit, faInfo, faSpinner, faSitemap, faScroll,
  faGraduationCap, faStarHalfAlt, faPercentage, faUndo, faFile, faTimesCircle,
  faLayerGroup, faFileAlt, faComment, faLink, faUnlink, faCalendarAlt, faCheckCircle,
  faClock, faBan, faUsers, faCheck, faTimes, faAngleDown, faAngleRight, faBold,
  faItalic, faStrikethrough, faUnderline, faCode, faParagraph, faListOl, faQuoteRight,
  faFileCsv, faCalendar, faChevronRight, faChevronDown, faChalkboardTeacher, faQuestionCircle,
  faUserSecret, faTicketAlt
)

Vue.component('fa', FontAwesomeIcon)

dom.watch()
