import * as types from '../mutation-types'

// State
export const state = {
  salutations: null,
  jobTitles: null,
  statuses: null,
  departments: null,
  roles: null,
  categories: null,
  levels: null,
  subjects: null,
  operators: null,
  valueTypes: null,
  subjectStatuses: null,
  fields: null,
  itemTypes: null,
  relations: null,
  measures: null,
  modules: null,
  evidenceTypes: null,
  fieldTypes: null,
  meetingTypeBlocks: null,
  meetingTypes: null,
  discussionPoints: null,
  meetingOutcomes: null,
  meetingStatuses: null,
  mailTypes: null,
  trainingAttendeeStatuses: null,
  cancellationReasons: null,
  trainingVenues: null,
  trainingSessionStatuses: null,
  courses: null,
  trainers: null,
  meetingCurrentLevels: null,
  secondManagerApprovals: null
}

// Getters
export const getters = {
  salutations: state => state.salutations,
  jobTitles: state => state.jobTitles,
  statuses: state => state.statuses,
  departments: state => state.departments,
  roles: state => state.roles,
  rolesObject: state => {
    return Object.assign({}, ...state.roles.map(role => {
      return ({ [role.value]: role.name })
    }))
  },
  categories: state => state.categories,
  levels: state => state.levels,
  subjects: state => state.subjects,
  operators: state => state.operators,
  valueTypes: state => state.valueTypes,
  subjectStatuses: state => state.subjectStatuses,
  fields: state => state.fields,
  itemTypes: state => state.itemTypes,
  relations: state => state.relations,
  measures: state => state.measures,
  modules: state => state.modules,
  evidenceTypes: state => state.evidenceTypes,
  fieldTypes: state => state.fieldTypes,
  meetingTypeBlocks: state => state.meetingTypeBlocks,
  meetingTypes: state => state.meetingTypes,
  discussionPoints: state => state.discussionPoints,
  meetingOutcomes: state => state.meetingOutcomes,
  meetingStatuses: state => state.meetingStatuses,
  mailTypes: state => state.mailTypes,
  trainingAttendeeStatuses: state => state.trainingAttendeeStatuses,
  cancellationReasons: state => state.cancellationReasons,
  trainingVenues: state => state.trainingVenues,
  trainingSessionStatuses: state => state.trainingSessionStatuses,
  courses: state => state.courses,
  trainers: state => state.trainers,
  meetingCurrentLevels: state => state.meetingCurrentLevels,
  secondManagerApprovals: state => state.secondManagerApprovals
}

// Mutations
export const mutations = {
  [types.SET_SALUTATIONS] (state, salutations) {
    state.salutations = salutations.map(
      function (salutation) {
        return {
          name: salutation.name,
          value: salutation.id
        }
      }
    )
  },

  [types.SET_JOB_TITLES] (state, jobTitles) {
    state.jobTitles = jobTitles.map(
      function (jobTitle) {
        return {
          name: jobTitle.name,
          value: jobTitle.id
        }
      }
    )
  },

  [types.SET_STATUSES] (state, statuses) {
    state.statuses = statuses.map(
      function (status) {
        return {
          name: status.name,
          value: status.id
        }
      }
    )
  },

  [types.SET_DEPARTMENTS] (state, departments) {
    state.departments = departments.map(
      function (department) {
        return {
          name: department.name,
          value: department.id
        }
      }
    )
  },

  [types.SET_ROLES] (state, roles) {
    state.roles = roles.map(
      function (role) {
        return {
          name: role.name,
          syncUsersSubjectStatuses: role.sync_users_subject_statuses,
          value: role.id
        }
      }
    )
  },

  [types.SET_CATEGORIES] (state, categories) {
    state.categories = categories.map(
      function (category) {
        return {
          name: category.name,
          value: category.id
        }
      }
    )
  },

  [types.SET_LEVELS] (state, levels) {
    state.levels = levels.map(
      function (level) {
        return {
          name: level.name,
          value: level.id,
          label: level.label,
          user_label: level.user_label,
          order: level.order
        }
      }
    )
  },

  [types.SET_SUBJECTS] (state, subjects) {
    state.subjects = subjects.map(
      function (subject) {
        return {
          name: subject.name,
          value: subject.id
        }
      }
    )
  },

  [types.SET_OPERATORS] (state, operators) {
    state.operators = operators.map(
      function (operator) {
        return {
          name: operator.name,
          value: operator.id,
          label: operator.label
        }
      }
    )
  },

  [types.SET_VALUE_TYPES] (state, valueTypes) {
    state.valueTypes = valueTypes.map(
      function (valueType) {
        return {
          name: valueType.name,
          value: valueType.id,
          label: valueType.label
        }
      }
    )
  },

  [types.SET_SUBJECT_STATUSES] (state, subjectStatuses) {
    state.subjectStatuses = subjectStatuses.map(
      function (subjectStatus) {
        return {
          name: subjectStatus.name,
          value: subjectStatus.id,
          label: subjectStatus.label,
          colorHex: subjectStatus.color_hex
        }
      }
    )
  },

  [types.SET_FIELDS] (state, fields) {
    state.fields = fields.map(
      function (field) {
        return {
          name: field.name,
          value: field.id,
          label: field.label
        }
      }
    )
  },

  [types.SET_ITEM_TYPES] (state, itemTypes) {
    state.itemTypes = itemTypes.map(
      function (itemType) {
        return {
          name: itemType.name,
          value: itemType.id,
          label: itemType.label
        }
      }
    )
  },

  [types.SET_RELATIONS] (state, relations) {
    state.relations = relations.map(
      function (relation) {
        return {
          name: relation.name,
          value: relation.id,
          label: relation.label
        }
      }
    )
  },

  [types.SET_MEASURES] (state, measures) {
    state.measures = measures.map(
      function (measure) {
        return {
          name: measure.name,
          value: measure.id,
          evidenceCriteria: measure.evidence_criteria
        }
      }
    )
  },

  [types.SET_MODULES] (state, modules) {
    state.modules = modules.map(
      function (Module) {
        return {
          name: Module.name,
          value: Module.value,
          showInLists: Module.showInLists,
          showInTrash: Module.showInTrash
        }
      }
    )
  },

  [types.SET_EVIDENCE_TYPES] (state, evidenceTypes) {
    state.evidenceTypes = evidenceTypes.map(
      function (evidenceType) {
        return {
          name: evidenceType.name,
          value: evidenceType.id
        }
      }
    )
  },

  [types.SET_FIELD_TYPES] (state, fieldTypes) {
    state.fieldTypes = fieldTypes.map(
      function (fieldType) {
        return {
          name: fieldType.name,
          value: fieldType.id
        }
      }
    )
  },

  [types.SET_MEETING_TYPE_BLOCKS] (state, meetingTypeBlocks) {
    state.meetingTypeBlocks = meetingTypeBlocks.map(
      function (meetingTypeBlock) {
        return {
          name: meetingTypeBlock.name,
          value: meetingTypeBlock.id,
          order: meetingTypeBlock.order
        }
      }
    )
  },

  [types.SET_MEETING_TYPES] (state, meetingTypes) {
    state.meetingTypes = meetingTypes.map(
      function (meetingType) {
        return {
          name: meetingType.name,
          value: meetingType.id,
          guidance: meetingType.guidance,
          published: meetingType.published,
          roles: meetingType.roles
        }
      }
    )
  },

  [types.SET_DISCUSSION_POINTS] (state, discussionPoints) {
    state.discussionPoints = discussionPoints.map(
      function (discussionPoint) {
        return {
          name: discussionPoint.name,
          value: discussionPoint.id
        }
      }
    )
  },

  [types.SET_MEETING_OUTCOMES] (state, meetingOutcomes) {
    state.meetingOutcomes = meetingOutcomes.map(
      function (meetingOutcome) {
        return {
          name: meetingOutcome.name,
          value: meetingOutcome.id
        }
      }
    )
  },

  [types.SET_MEETING_STATUSES] (state, meetingStatuses) {
    state.meetingStatuses = meetingStatuses.map(
      function (meetingStatus) {
        return {
          name: meetingStatus.name,
          value: meetingStatus.id,
          colorHex: meetingStatus.color_hex
        }
      }
    )
  },

  [types.SET_MAIL_TYPES] (state, mailTypes) {
    state.mailTypes = mailTypes.map(
      function (mailType) {
        return {
          name: mailType.name,
          value: mailType.id
        }
      }
    )
  },

  [types.SET_TRAINING_ATTENDEE_STATUSES] (state, trainingAttendeeStatuses) {
    state.trainingAttendeeStatuses = trainingAttendeeStatuses.map(
      function (trainingAttendeeStatus) {
        return {
          name: trainingAttendeeStatus.name,
          value: trainingAttendeeStatus.id,
          description: trainingAttendeeStatus.description
        }
      }
    )
  },

  [types.SET_CANCELLATION_REASONS] (state, cancellationReasons) {
    state.cancellationReasons = cancellationReasons.map(
      function (cancellationReason) {
        return {
          name: cancellationReason.name,
          value: cancellationReason.id,
          roles: cancellationReason.roles.map((role) => {
            return role.name
          })
        }
      }
    )
  },

  [types.SET_TRAINING_VENUES] (state, trainingVenues) {
    state.trainingVenues = trainingVenues.map(
      function (trainingVenue) {
        return {
          name: trainingVenue.name,
          value: trainingVenue.id
        }
      }
    )
  },

  [types.SET_TRAINING_SESSION_STATUSES] (state, trainingSessionStatuses) {
    state.trainingSessionStatuses = trainingSessionStatuses.map(
      function (trainingSessionStatus) {
        return {
          name: trainingSessionStatus.name,
          value: trainingSessionStatus.id
        }
      }
    )
  },

  [types.SET_COURSES] (state, courses) {
    state.courses = courses.map(
      function (course) {
        return {
          name: course.name,
          value: course.id,
          roles: course.roles
        }
      }
    )
  },

  [types.SET_TRAINERS] (state, trainers) {
    state.trainers = trainers.map(
      function (trainer) {
        return {
          name: trainer.name,
          value: trainer.id
        }
      }
    )
  },

  [types.SET_SECOND_MANAGER_APPROVALS] (state, secondManagerApprovals) {
    state.secondManagerApprovals = secondManagerApprovals.map(
      function (secondManagerApprovals) {
        return {
          name: secondManagerApprovals.name,
          value: secondManagerApprovals.id
        }
      }
    )
  }

}
