var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item dropdown" }, [
    _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          role: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.locales[_vm.locale]) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dropdown-menu" },
      _vm._l(_vm.locales, function(value, key) {
        return _c(
          "a",
          {
            staticClass: "dropdown-item",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setLocale(key)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(value) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }