import * as types from '../mutation-types'

// State
export const state = {
  pageTitle: '',
  referral: null
}

// Getters
export const getters = {
  pageTitle: state => state.pageTitle,
  referral: state => state.referral
}

// Mutations
export const mutations = {
  [types.SET_PAGE_TITLE] (state, pageTitle) { state.pageTitle = pageTitle },
  [types.SET_REFERRAL] (state, referral) { state.referral = referral }
}

// actions
export const actions = {
  setReferral ({ commit }, payload) {
    commit(types.SET_REFERRAL, payload)
  }
}
