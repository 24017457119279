import store from '~/store'
import swal from 'sweetalert2'
import i18n from '~/plugins/i18n'
import errorHandler from '~/plugins/error-handler'

export default (to, from, next) => {
  if (!store.getters['auth/check'] || !store.getters['auth/user']) {
    next({ name: 'login' })
  } else {
    const user = store.getters['auth/user']
    const { permissions } = user

    if (to.meta.adminOnly) {
      if (
        permissions.indexOf(to.meta.permission + ' admin') >= 0 &&
        permissions.indexOf(to.meta.permission + ' ' + to.meta.module) >= 0
      ) {
        next()
      } else {
        if (from.path === '/' || from.path === '/login/') {
          next({ name: 'dashboard' })
        } else {
          next(false)
        }

        swal.fire({
          type: 'error',
          title: i18n.t('general.error.permission_denied'),
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 5000
        })
      }
    } else if (permissions.indexOf(to.meta.permission + ' ' + to.meta.module) >= 0) {
      next()
    } else {
      if (from.path === '/') {
        next({ name: 'dashboard' })
      } else {
        next(false)
      }

      errorHandler.showErrorInToast(i18n.t('general.error.permission_denied'))
    }
  }
}
