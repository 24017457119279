var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.getters["auth/check"]
    ? _c(
        "nav",
        { staticClass: "navbar navbar-expand-lg navbar-dark bg-white" },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row w-100" }, [
              _c("div", { staticClass: "col-9 col-sm-10 col-md-9 col-lg-10" }, [
                _c(
                  "div",
                  { staticClass: "row", attrs: { id: "navbar-pill-row" } },
                  [
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "Slide",
                          { attrs: { width: "270", closeOnNavigation: true } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "dropdown-item pl-3 d-flex align-items-center",
                                attrs: { to: { name: "categories.list" } }
                              },
                              [
                                _c("fa", {
                                  staticClass: "mr-1",
                                  staticStyle: { color: "#263586" },
                                  attrs: { icon: "sitemap", "fixed-width": "" }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tc("general.common.category", 2)
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.user.role_has_levels ||
                            _vm.user.role === "Manager"
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "dropdown-item pl-3 d-flex align-items-center",
                                    attrs: {
                                      to: { name: "evidences.required" }
                                    }
                                  },
                                  [
                                    _c("fa", {
                                      staticClass: "mr-1",
                                      staticStyle: { color: "#B15E1C" },
                                      attrs: {
                                        icon: "scroll",
                                        "fixed-width": ""
                                      }
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("general.common.evidence")
                                        ) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "dropdown-item pl-3 d-flex align-items-center",
                                attrs: { to: { name: "training.list" } }
                              },
                              [
                                _c("fa", {
                                  staticClass: "mr-1",
                                  staticStyle: { color: "#467D3F" },
                                  attrs: {
                                    icon: "graduation-cap",
                                    "fixed-width": ""
                                  }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tc("general.common.booked_training")
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "dropdown-item pl-3 d-flex align-items-center",
                                attrs: {
                                  to: { name: "training-requests.list" }
                                }
                              },
                              [
                                _c("fa", {
                                  staticClass: "mr-1",
                                  staticStyle: { color: "#7d1f1a" },
                                  attrs: {
                                    icon: "ticket-alt",
                                    "fixed-width": ""
                                  }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tc(
                                        "general.common.training_request",
                                        2
                                      )
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "dropdown-item pl-3 d-flex align-items-center",
                                attrs: { to: { name: "meetings.list" } }
                              },
                              [
                                _c("fa", {
                                  staticClass: "mr-1",
                                  staticStyle: { color: "#732049" },
                                  attrs: {
                                    icon: "calendar-alt",
                                    "fixed-width": ""
                                  }
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tc("general.common.meeting", 2)
                                    ) +
                                    "\n              "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.$store.getters["auth/hasPermission"](
                              "view admin"
                            )
                              ? _c("hr")
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$store.getters["auth/hasPermission"](
                              "view admin"
                            )
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "dropdown-item pl-3 d-flex align-items-center",
                                    attrs: { to: { name: "admin.stats" } }
                                  },
                                  [
                                    _c("fa", {
                                      staticClass: "mr-1",
                                      staticStyle: { color: "#000000" },
                                      attrs: { icon: "cog", "fixed-width": "" }
                                    }),
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("section.nav.settings")) +
                                        "\n              "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-10 angled-box-right d-flex align-items-center"
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "navbar-brand",
                            attrs: {
                              to: { name: _vm.user ? "dashboard" : "login" }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/images/logo_white_without_text.png",
                                alt: "SMT Logo",
                                width: "100px"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h1",
                          { attrs: { id: "page-title" } },
                          [
                            _vm.$route.meta.title &&
                            !_vm.$route.meta.dynamicTitle
                              ? [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$te(
                                          "section.nav." + _vm.$route.meta.title
                                        )
                                          ? _vm.$t(
                                              "section.nav." +
                                                _vm.$route.meta.title
                                            )
                                          : _vm.$tc(
                                              "general.common." +
                                                _vm.$route.meta.title,
                                              2
                                            )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$store.getters[
                                          "page-info/pageTitle"
                                        ]
                                      ) +
                                      "\n              "
                                  )
                                ]
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3 col-sm-2 col-md-3 col-lg-2" }, [
                _vm.user
                  ? _c(
                      "ul",
                      {
                        staticClass: "navbar-nav",
                        attrs: { id: "navbar-user-menu" }
                      },
                      [
                        _c("li", { staticClass: "nav-item dropdown" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link dropdown-toggle text-dark",
                              attrs: {
                                href: "#",
                                role: "button",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("fa", {
                                    attrs: {
                                      icon: "user-circle",
                                      id: "default-avatar",
                                      "fixed-width": ""
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", { attrs: { id: "name-text" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.user.first_name)
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("capitalize")(_vm.user.last_name)
                                    )
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "dropdown-menu" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item pl-3",
                                  attrs: { to: { name: "settings.profile" } }
                                },
                                [
                                  _c("fa", {
                                    attrs: { icon: "cog", "fixed-width": "" }
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("section.nav.user_settings")
                                      ) +
                                      "\n              "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item pl-3",
                                  attrs: { to: { name: "users.feedback" } }
                                },
                                [
                                  _c("fa", {
                                    attrs: {
                                      icon: "comment",
                                      "fixed-width": ""
                                    }
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("section.nav.user_feedback")
                                      ) +
                                      "\n              "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item pl-3",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.logout($event)
                                    }
                                  }
                                },
                                [
                                  _c("fa", {
                                    attrs: {
                                      icon: "sign-out-alt",
                                      "fixed-width": ""
                                    }
                                  }),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("general.operation.logout")
                                      ) +
                                      "\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }