// auth.js

export const FETCH_USER = 'FETCH_USER'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

export const LOGOUT = 'LOGOUT'
export const PROCESS_LOGIN = 'PROCESS_LOGIN'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// lists.js
export const SET_SALUTATIONS = 'SET_SALUTATIONS'
export const SET_JOB_TITLES = 'SET_JOB_TITLES'
export const SET_STATUSES = 'SET_STATUSES'
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
export const SET_ROLES = 'SET_ROLES'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_LEVELS = 'SET_LEVELS'
export const SET_SUBJECTS = 'SET_SUBJECTS'
export const SET_OPERATORS = 'SET_OPERATORS'
export const SET_VALUE_TYPES = 'SET_VALUE_TYPES'
export const SET_SUBJECT_STATUSES = 'SET_SUBJECT_STATUSES'
export const SET_FIELDS = 'SET_FIELDS'
export const SET_ITEM_TYPES = 'SET_ITEM_TYPES'
export const SET_RELATIONS = 'SET_RELATIONS'
export const SET_MEASURES = 'SET_MEASURES'
export const SET_MODULES = 'SET_MODULES'
export const SET_EVIDENCE_TYPES = 'SET_EVIDENCE_TYPES'
export const SET_FIELD_TYPES = 'SET_FIELD_TYPES'
export const SET_MEETING_TYPE_BLOCKS = 'SET_MEETING_TYPE_BLOCKS'
export const SET_MEETING_TYPES = 'SET_MEETING_TYPES'
export const SET_DISCUSSION_POINTS = 'SET_DISCUSSION_POINTS'
export const SET_MEETING_OUTCOMES = 'SET_MEETING_OUTCOMES'
export const SET_MEETING_STATUSES = 'SET_MEETING_STATUSES'
export const SET_MAIL_TYPES = 'SET_MAIL_TYPES'
export const SET_TRAINING_ATTENDEE_STATUSES = 'SET_TRAINING_ATTENDEE_STATUSES'
export const SET_CANCELLATION_REASONS = 'SET_CANCELLATION_REASONS'
export const SET_TRAINING_VENUES = 'SET_TRAINING_VENUES'
export const SET_TRAINING_SESSION_STATUSES = 'SET_TRAINING_SESSION_STATUSES'
export const SET_COURSES = 'SET_COURSES'
export const SET_TRAINERS = 'SET_TRAINERS'
export const SET_SECOND_MANAGER_APPROVALS = 'SET_SECOND_MANAGER_APPROVAL'

// page-info.js
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_REFERRAL = 'SET_REFERRAL'
