<template>
  <div class="main-layout">
    <navbar/>

    <div class="container-fluid">
      <child/>
    </div>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar.vue'

export default {
  name: 'MainLayout',

  components: {
    Navbar
  }
}
</script>

<style>
  body {
    overflow: hidden visible;
  }
</style>