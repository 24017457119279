<template>
  <div id="app" class="h-100">
    <loading ref="loading"/>

    <transition name="page" mode="out-in">
      <component v-if="layout" :is="layout"></component>
    </transition>
  </div>
</template>

<script>
import Loading from './Loading.vue'
import { mapMutations } from 'vuex'
import * as types from '../store/mutation-types'
import axios from 'axios'
import errorHandler from '~/plugins/error-handler'
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js'

// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component
    return components
  }, {})

export default {
  el: '#app',

  components: {
    Loading
  },

  data: () => ({
    layout: null,
    defaultLayout: 'default'
  }),

  metaInfo () {
    const { appName } = window.config

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },

  mounted () {
    /** Turn on below for console log output of all pusher logs - THIS SHOULD NOT BE LEFT ON **/
    // Pusher.logToConsole = true

    this.$loading = this.$refs.loading
    this.setSalutations(salutationsJson)
    this.setJobTitles(jobTitlesJson)
    this.setStatuses(statusesJson)
    this.setDepartments(departmentsJson)
    this.setRoles(rolesJson)
    this.setCategories(categoriesJson)
    this.setLevels(levelsJson)
    this.setSubjects(subjectsJson)
    this.setOperators(operatorsJson)
    this.setValueTypes(valueTypesJson)
    this.setSubjectStatuses(subjectStatusesJson)
    this.setFields(fieldsJson)
    this.setItemTypes(itemTypesJson)
    this.setRelations(relationsJson)
    this.setMeasures(measuresJson)
    this.setModules(modulesJson)
    this.setEvidenceTypes(evidenceTypesJson)
    this.setFieldTypes(fieldTypesJson)
    this.setMeetingTypeBlocks(meetingTypeBlocksJson)
    this.setMeetingTypes(meetingTypesJson)
    this.setDiscussionPoints(discussionPointsJson)
    this.setMeetingOutcomes(meetingOutcomesJson)
    this.setMeetingStatuses(meetingStatusesJson)
    this.setMailTypes(mailTypesJson)
    this.setTrainingAttendeeStatuses(trainingAttendeeStatusesJson)
    this.setCancellationReasons(cancellationReasonsJson)
    this.setTrainingVenues(trainingVenuesJson)
    this.setTrainingSessionStatuses(trainingSessionStatusesJson)
    this.setCourses(coursesJson)
    this.setTrainers(trainersJson)
    this.setSecondManagerApprovals(secondManagerApprovalJson)

    if (typeof token !== 'undefined') {
      this.$store.dispatch('auth/processLogin', {
        token: token,
        user: user,
        remember: true
      })
    }

    this.$echo.channel('list.updated').listen('.list-updated', (data) => {
      this.updateList(data)
    })
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = (typeof layouts[layout].default !== 'undefined' ? layouts[layout].default : layouts[layout])
    },

    async updateList (listClassName) {
      try {
        const response = await axios.post('/api/lists', {
          list_name: listClassName
        })

        this['set' + response.data.list_name](response.data.list)
      } catch (error) {
        errorHandler.handle(error)
      }
    },

    ...mapMutations('lists', {
      setSalutations: types.SET_SALUTATIONS,
      setJobTitles: types.SET_JOB_TITLES,
      setStatuses: types.SET_STATUSES,
      setDepartments: types.SET_DEPARTMENTS,
      setRoles: types.SET_ROLES,
      setCategories: types.SET_CATEGORIES,
      setLevels: types.SET_LEVELS,
      setSubjects: types.SET_SUBJECTS,
      setOperators: types.SET_OPERATORS,
      setValueTypes: types.SET_VALUE_TYPES,
      setSubjectStatuses: types.SET_SUBJECT_STATUSES,
      setFields: types.SET_FIELDS,
      setItemTypes: types.SET_ITEM_TYPES,
      setRelations: types.SET_RELATIONS,
      setMeasures: types.SET_MEASURES,
      setModules: types.SET_MODULES,
      setEvidenceTypes: types.SET_EVIDENCE_TYPES,
      setFieldTypes: types.SET_FIELD_TYPES,
      setMeetingTypeBlocks: types.SET_MEETING_TYPE_BLOCKS,
      setMeetingTypes: types.SET_MEETING_TYPES,
      setDiscussionPoints: types.SET_DISCUSSION_POINTS,
      setMeetingOutcomes: types.SET_MEETING_OUTCOMES,
      setMeetingStatuses: types.SET_MEETING_STATUSES,
      setMailTypes: types.SET_MAIL_TYPES,
      setTrainingAttendeeStatuses: types.SET_TRAINING_ATTENDEE_STATUSES,
      setCancellationReasons: types.SET_CANCELLATION_REASONS,
      setTrainingVenues: types.SET_TRAINING_VENUES,
      setTrainingSessionStatuses: types.SET_TRAINING_SESSION_STATUSES,
      setCourses: types.SET_COURSES,
      setTrainers: types.SET_TRAINERS,
      setSecondManagerApprovals: types.SET_SECOND_MANAGER_APPROVALS
    })
  }
}
</script>
