<template>
    <div class="container-fluid h-100 d-flex">
        <child/>
    </div>
</template>

<script>
export default {
  name: 'NoNavLayoutCenter'
}
</script>

<style scoped>
    body {
        overflow:hidden;
    }
</style>
