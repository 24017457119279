import Vue from 'vue'
import VueEcho from 'vue-echo-laravel'
import Echo from 'laravel-echo'

const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: window.config.broadcastKey,
  cluster: 'eu',
  forceTLS: true
})

Vue.use(VueEcho, EchoInstance)
