import Vue from 'vue'

import { BadgerAccordion } from 'vue-badger-accordion'
import BadgerAccordionItem from '../components/accordion/BadgerAccordionItem'

Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)

Vue.prototype.$badgerIcons = {
  opened: '<i class="fas fa-chevron-down mr-1"></i>',
  closed: '<i class="fas fa-chevron-right mr-1"></i>'
}
